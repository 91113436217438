const faqAccordion = {
  name: 'js-accordion',
  selectors: {
    accordion: '.js-faqAccordion',
    accordionItem: '.js-accordionItem',
    accordionSummary: '.js-accordionItemSummary',
    accordionContentWrapper: '.js-accordionContentWrapper',
  },
  el: {
    $accordion: null,
  },
  els: {
    $accordionItem: null,
  },

  toggleTriggerEventListener() {
    this.els.$accordionItem.forEach((accordionItem) => {
      accordionItem
        .querySelector(this.selectors.accordionSummary)
        .addEventListener('click', (e) => {
          const contentWrapper = accordionItem.querySelector(
            this.selectors.accordionContentWrapper,
          );

          const onAnimationEnd = (animationItem) =>
            contentWrapper.addEventListener('animationend', animationItem, {
              once: true,
            });

          requestAnimationFrame(() => contentWrapper.classList.add('animation'));
          onAnimationEnd(() => contentWrapper.classList.remove('animation'));

          if (accordionItem.getAttribute('open') !== null) {
            e.preventDefault();

            contentWrapper.classList.add('collapsing');

            onAnimationEnd(() => {
              accordionItem.removeAttribute('open');
              contentWrapper.classList.remove('collapsing');
            });
          }
        });
    });
  },

  setElements() {
    this.el.$accordion = document.querySelector(this.selectors.accordion);

    if (this.el.$accordion) {
      this.els.$accordionItem = this.el.$accordion.querySelectorAll(
        this.selectors.accordionItem,
      );
    }
  },

  init() {
    this.setElements();

    if (this.els.$accordionItem) {
      this.toggleTriggerEventListener();
    }
  },
};

export default faqAccordion;

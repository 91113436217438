/**
 * appHeader
 */

/*eslint-disable */
const appHeader = {
  cfg: {
    name: `appHeader`,
  },

  init() {
    // instantiate HeadsUp (auto-hide header)
    new headsUp('.o-appHeader');
  },
};

export default appHeader;

/*
 * Description of what this file is for
 *
 * @package  kinexon
 * @author Mario Kishalmi [mario@fredmansky.at]
 * oneHeader
 */

const oneHeader = {
  cfg: {
    name: 'oneHeader',
    selectors: {
      oneHeader: 'o-oneHeader',
    },
    classes: {
      active: 'is-active',
      inActive: 'is-inActive',
      hidden: 'is-hidden',
      visible: 'is-visible',
    },
    el: {
      $oneHeader: undefined,
    },
    els: {
      $oneHeader: undefined,
    },
  },

  log(msg) {
    console.log(msg);
  },

  setElements() {
    console.debug('Set Elements…');
    this.cfg.el.$oneHeader = document.querySelector(this.cfg.selectors.oneHeader);
  },

  init() {
    // console.debug(`Init: ${this.cfg.name}`);
    // this.setElements();

    const appHeader = document.querySelector('.o-appHeader');
    const introSection = document.querySelector('.o-oneHeader');

    if (appHeader && introSection) {
      appHeader.classList.toggle(
        '.o-appHeader--white',
        introSection.classList.contains('o-oneHeader--fullWidth'),
      );
    }
  },
};

export default oneHeader;

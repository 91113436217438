const splitHero = {
  cfg: {
    name: 'splitHero',
    selectors: {
      splitHero: '.o-splitHero',
      contentWrapper: '.o-splitHero .o-splitHero__contentWrapper',
    },
    classes: {},
    el: {
      $splitHero: undefined,
    },
    els: {
      $contentWrappers: undefined,
    },
  },

  setElements() {
    this.cfg.el.$splitHero = document.querySelector(this.cfg.selectors.splitHero);
    this.cfg.els.$contentWrappers = document.querySelectorAll(
      this.cfg.selectors.contentWrapper,
    );
  },

  isInternetExplorerOrEdge() {
    return document.documentMode || /Edge\//.test(navigator.userAgent);
  },

  setMaxWidth() {
    const maxWidth = document.documentElement.clientWidth;
    this.cfg.el.$splitHero.style.maxWidth = `${maxWidth}px`;

    this.cfg.els.$contentWrappers.forEach((contentWrapper) => {
      if (this.isInternetExplorerOrEdge()) {
        contentWrapper.style.width = `${maxWidth / 2}px`;
      } else {
        contentWrapper.style.width = `${maxWidth}px`;
      }
    });
  },

  init() {
    document.addEventListener('DOMContentLoaded', () => {
      this.setElements();

      if (this.cfg.el.$splitHero) {
        this.setMaxWidth = this.setMaxWidth.bind(this);
        this.setMaxWidth();

        window.addEventListener('resize', this.setMaxWidth);
      }
    });
  },
};

export default splitHero;

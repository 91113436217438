/*
 * Description of what this file is for
 *
 * @package  craft3
 * @author David Hellmann [david@hellmann.io]
 * navMain
 */

// import _debounce from 'lodash/debounce';

const navMain = {
  cfg: {
    name: 'navMain',
    selectors: {
      navMain: '.m-navMain',
      linksMain: '.m-navMain__listItem--level-1',
      innerLinksMain: '.m-navMain__listItemInner--level-1',
      submenu: '.m-navMain__submenu--level-2',
      burgerIcon: '.m-navMain__burger',
      closeIcon: '.m-navMain__close',
      body: 'body',
      html: 'html',
    },
    classes: {
      active: 'is-active',
      inActive: 'is-inActive',
      hidden: 'is-hidden',
      visible: 'is-visible',
    },
    el: {
      $navMain: undefined,
      $body: undefined,
      $html: undefined,
      $burgerIcon: undefined,
      $closeIcon: undefined,
    },
    els: {
      $linkListMain: undefined,
      $innerLinkListMain: undefined,
      $submenues: undefined,
    },
  },

  setElements() {
    this.cfg.el.$navMain = document.querySelector(this.cfg.selectors.navMain);
    this.cfg.el.$body = document.querySelector(this.cfg.selectors.body);
    this.cfg.el.$html = document.querySelector(this.cfg.selectors.html);
    this.cfg.el.$burgerIcon = document.querySelector(this.cfg.selectors.burgerIcon);
    this.cfg.el.$closeIcon = document.querySelector(this.cfg.selectors.closeIcon);
    this.cfg.els.$linkListMain = document.querySelectorAll(this.cfg.selectors.linksMain);
    this.cfg.els.$innerLinkListMain = document.querySelectorAll(
      this.cfg.selectors.innerLinksMain,
    );
    this.cfg.els.$submenues = document.querySelectorAll(this.cfg.selectors.submenu);
  },

  addEventListeners() {
    this.cfg.els.$linkListMain.forEach((link) => {
      if ('ontouchstart' in window) {
        // mobile - onTouch
        link.addEventListener(
          'touchstart',
          () => {
            this.cfg.touch = true;
          },
          {
            passive: true,
          },
        );

        link.addEventListener(
          'touchmove',
          () => {
            this.cfg.touch = false;
          },
          {
            passive: true,
          },
        );

        link.addEventListener('click', this.evtMouseClick);
      } else {
        // desktop
        link.addEventListener('click', this.evtMouseClick);
      }
    });

    // -- MOBILE MENU --//
    if ('ontouchstart' in window) {
      // mobile - onTouch
      this.cfg.el.$burgerIcon.addEventListener(
        'touchstart',
        () => {
          this.cfg.touch = true;
        },
        {
          passive: true,
        },
      );

      this.cfg.el.$closeIcon.addEventListener(
        'touchstart',
        () => {
          this.cfg.touch = true;
        },
        {
          passive: true,
        },
      );

      this.cfg.el.$burgerIcon.addEventListener(
        'touchmove',
        () => {
          this.cfg.touch = false;
        },
        {
          passive: true,
        },
      );

      this.cfg.el.$closeIcon.addEventListener(
        'touchmove',
        () => {
          this.cfg.touch = false;
        },
        {
          passive: true,
        },
      );

      this.cfg.el.$burgerIcon.addEventListener(
        'touchend',
        () => {
          this.cfg.el.$burgerIcon.classList.toggle(this.cfg.classes.visible);
          this.cfg.el.$closeIcon.classList.toggle(this.cfg.classes.visible);
          this.openMobileMenu();
        },
        {
          passive: true,
        },
      );

      this.cfg.el.$closeIcon.addEventListener(
        'touchend',
        () => {
          this.cfg.el.$burgerIcon.classList.toggle(this.cfg.classes.visible);
          this.cfg.el.$closeIcon.classList.toggle(this.cfg.classes.visible);
          this.closeMobileMenu();
        },
        {
          passive: true,
        },
      );
    } else {
      // mobile - onClick
      this.cfg.el.$burgerIcon.addEventListener('click', () => {
        this.cfg.el.$burgerIcon.classList.toggle(this.cfg.classes.visible);
        this.cfg.el.$closeIcon.classList.toggle(this.cfg.classes.visible);
        this.openMobileMenu();
      });
      this.cfg.el.$closeIcon.addEventListener('click', () => {
        this.cfg.el.$burgerIcon.classList.toggle(this.cfg.classes.visible);
        this.cfg.el.$closeIcon.classList.toggle(this.cfg.classes.visible);
        this.closeMobileMenu();
      });
    }
  },

  removeActiveClasses(keepSubmenusVisible) {
    if (!keepSubmenusVisible) {
      this.cfg.els.$submenues.forEach((submenu) => {
        submenu.classList.remove(this.cfg.classes.visible);
      });
    }
    this.cfg.els.$innerLinkListMain.forEach((link) => {
      link.classList.remove(this.cfg.classes.active);
    });
  },

  getScrollbarWidth() {
    const inner = document.createElement('p');
    inner.style.width = '100%';
    inner.style.height = '200px';

    const outer = document.createElement('div');
    outer.style.position = 'absolute';
    outer.style.top = '0px';
    outer.style.left = '0px';
    outer.style.visibility = 'hidden';
    outer.style.width = '200px';
    outer.style.height = '150px';
    outer.style.overflow = 'hidden';
    outer.appendChild(inner);

    document.body.appendChild(outer);
    const w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    let w2 = inner.offsetWidth;
    if (w1 === w2) {
      w2 = outer.clientWidth;
    }

    document.body.removeChild(outer);

    return w1 - w2;
  },

  lockBody(scrollBarHack) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    this.cfg.el.$body.classList.add(this.cfg.classes.inActive);
    this.cfg.el.$html.classList.add(this.cfg.classes.inActive);

    // dirty hacks for all non-macs ^^ (only on desktop)
    if (scrollBarHack)
      document.querySelector('.o-appHeader__inner').style.paddingRight =
        `${this.scrollBarWidth}px`;
  },

  freeBody() {
    this.cfg.el.$body.classList.remove(this.cfg.classes.inActive);
    this.cfg.el.$html.classList.remove(this.cfg.classes.inActive);

    document.querySelector('.o-appHeader__inner').style.paddingRight = 0; // no harm in always removing the scroll hack
  },

  checkIfActive(evt) {
    // if main menu point is clicked
    if (
      Object.values(evt.target.classList).includes('m-navMain__listItemInner--level-1')
    ) {
      return evt.target.innerHTML === this.active;
    } else if (
      Object.values(evt.target.classList).includes('m-navMain__listItem--level-1')
    ) {
      return (
        evt.target.querySelector('.m-navMain__listItemInner--level-1').innerHTML ===
        this.active
      );
    }
    return true;
  },

  toggleHeaderWhite(isActive) {
    // we want the header dark if: the navi is closed and the header passed it's --white modifier on to the navi
    const dark =
      !isActive &&
      document.querySelector('.m-navMain').classList.contains('m-navMain--white');
    document.querySelector('.o-appHeader').classList.toggle('o-appHeader--white', dark);
  },

  evtMouseClick(evt) {
    // MUST BE IN SYNC WITH THE _style.scss
    const isHamburger = window.innerWidth < 1000; // hamburger menu (mobile)
    // NOTE: this ^ is rather terrible, should use detection like
    //  !!document.querySelector('.m-navMain__close.is-visible:not([style="display:none"])')
    // which in this case isn't reliable either, 'cause the is-visible classes do not get set properly :P

    if (this.checkIfActive(evt)) {
      // the active menu point has been clicked → DE-activate

      if (evt.target.tagName === 'A') {
        // link was clicked → reloading anyways, don't close the navi
        return;
      }

      let submenu =
        evt.target.parentElement.querySelector(
          `${this.cfg.selectors.submenu}.is-visible`,
        ) || evt.target;
      while (
        submenu &&
        !Object.values(submenu.classList).includes('m-navMain__submenu--level-2')
      ) {
        submenu = submenu.parentElement;
      }
      if (submenu) {
        submenu.classList.remove(this.cfg.classes.visible);
      }

      // clean up open active items (also children)
      evt.target.classList.remove(this.cfg.classes.active);
      this.active = null;

      if (!isHamburger) {
        this.freeBody();
        this.toggleHeaderWhite(false);
      }
    } else {
      // a new menu point has been clicked → activate
      const submenu = evt.target.parentElement.querySelector(this.cfg.selectors.submenu);
      this.lockBody(true);

      this.removeActiveClasses(isHamburger);

      evt.target.classList.add(this.cfg.classes.active);
      this.active = evt.target.innerHTML;

      if (submenu) {
        submenu.classList.add(this.cfg.classes.visible);
      }

      if (!isHamburger) {
        this.toggleHeaderWhite(true);
      }
    }
  },

  evtFingerTouched(evt) {
    if (this.cfg.touch) {
      if (window.innerWidth < 1000) {
        // mobile menu
        if (this.checkIfActive(evt)) {
          let submenu =
            evt.target.parentElement.querySelector(
              `${this.cfg.selectors.submenu}.is-visible`,
            ) || evt.target;

          while (
            submenu &&
            !Object.values(submenu.classList).includes('m-navMain__submenu--level-2')
          ) {
            submenu = submenu.parentElement;
          }
          if (submenu) {
            submenu.classList.remove(this.cfg.classes.visible);
          }

          evt.target.classList.remove(this.cfg.classes.active);
          this.active = null;
        } else {
          const submenu = evt.target.parentElement.querySelector(
            this.cfg.selectors.submenu,
          );
          this.removeActiveClasses();
          evt.target.classList.add(this.cfg.classes.active);
          this.active = evt.target.innerHTML;

          if (submenu) {
            submenu.classList.add(this.cfg.classes.visible);
          }
        }
      } else {
        this.evtMouseClick(evt);
      }
    }
  },

  openMobileMenu() {
    this.lockBody();
    // remove all active classes
    document.querySelectorAll(`.${this.cfg.classes.active}`).forEach((el) => {
      el.classList.remove(this.cfg.classes.active);
    });

    const lTop = document.querySelector('.m-navMain__list--level-1');
    lTop.classList.add('is-active');
    lTop.scrollTo(0, 0);
  },

  closeMobileMenu() {
    this.freeBody();
    document
      .querySelector('.m-navMain__list--level-1')
      .classList.remove(this.cfg.classes.active);
    document.querySelectorAll(this.cfg.selectors.submenu).forEach((submenu) => {
      submenu.classList.remove(this.cfg.classes.visible);
    });
    this.active = null;
  },

  init() {
    this.active = null;
    this.removeActiveClasses = this.removeActiveClasses.bind(this);
    this.lockBody = this.lockBody.bind(this);
    this.freeBody = this.freeBody.bind(this);
    this.evtMouseClick = this.evtMouseClick.bind(this);
    this.evtFingerTouched = this.evtFingerTouched.bind(this);

    window.addEventListener('load', () => {
      this.setElements();
      this.addEventListeners();
    });

    this.scrollBarWidth = this.getScrollbarWidth();
  },
};

export default navMain;

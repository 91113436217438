/**
 * Scripts
 */

import bodyClass from '../scripts/bodyclass';
import disableHover from '../scripts/disableHover';
import browserDetect from '../scripts/bowser';
import resizeClass from '../scripts/resizeClass';
import '../scripts/lazyloading';
import '../scripts/fontfaceobserver';
import '../scripts/headsup';

// Modules Array
const scriptsArray = [bodyClass, disableHover, browserDetect, resizeClass];

const scripts = {
  // Scripts
  Scripts() {
    if (scriptsArray.length > 0) {
      scriptsArray.forEach((script) => {
        script.init();
      });
    }
  },

  init() {
    this.Scripts();
  },
};

export default scripts;

/**
 * modules
 */

// import preloader from '../../../templates/_atoms/preloader/_script';
import codeSnippet from '../../../templates/_atoms/codeSnippet/_script';
import entrySlider from '../../../templates/_organisms/entrySlider/_script';
import heroSlider from '../../../templates/_organisms/heroSlider/_script';
import imageSlider from '../../../templates/_organisms/imageSlider/_script';
import input from '../../../templates/_atoms/input/_script';
import textarea from '../../../templates/_atoms/textarea/_script';
import accordion from '../../../templates/_organisms/accordion/_script';
import gridOverlay from '../../../templates/_molecules/gridOverlay/_script';
import navMain from '../../../templates/_molecules/navMain/_script';
import tabContentSlider from '../../../templates/_organisms/tabContentSlider/_script';
import appHeader from '../../../templates/_organisms/appHeader/_script';
import oneHeader from '../../../templates/_organisms/oneHeader/_script';
import factSection from '../../../templates/_organisms/factSection/_script';
import dynamicForm from '../../../templates/_organisms/dynamicForm/_script';
import splitHero from '../../../templates/_organisms/splitHero/_script';
import faqAccordion from '../../../templates/_organisms/faqAccordion/_script';

// Modules Array
const modulesArray = [
  codeSnippet,
  entrySlider,
  imageSlider,
  input,
  textarea,
  accordion,
  gridOverlay,
  navMain,
  heroSlider,
  tabContentSlider,
  appHeader,
  oneHeader,
  factSection,
  dynamicForm,
  splitHero,
  faqAccordion,
];

const modules = {
  // Modules
  Modules() {
    if (modulesArray.length > 0) {
      modulesArray.forEach((module) => {
        module.init();
      });
    }
  },

  init() {
    this.Modules();
  },
};

export default modules;

/*
 * @package  kinexon
 * @author Mario Kishalmi [mario@fredmansky.at]
 * factSection
 */

import Flickity from 'flickity';

const factSection = {
  cfg: {
    slider: '.js-factSection',
    sliderOptions: {
      cellAlign: 'left',
      contain: true,
      draggable: true,
      lazyLoad: 2,
      selectedAttraction: 0.01,
      friction: 0.15,
      groupCells: true,
      setGallerySize: true,
      imagesLoaded: true,
      prevNextButtons: false,
      pageDots: true,
      wrapAround: false,
      adaptiveHeight: false,
      // watchCSS: true
    },
  },

  init() {
    const fResize = () => {
      const sliders = [...document.querySelectorAll(this.cfg.slider)];
      if (sliders) {
        sliders.forEach((slider) => {
          const flkty = new Flickity(slider, this.cfg.sliderOptions);
          flkty.resize();

          setTimeout(() => {
            flkty.resize();
            slider.classList.add('is-ready');
          }, 1000);

          flkty.on('dragStart', () => (document.ontouchmove = (e) => e.preventDefault()));
          flkty.on('dragEnd', () => (document.ontouchmove = () => true));
        });
      }
    };
    window.addEventListener('load', fResize);
    window.addEventListener('resize', fResize);
  },
};

export default factSection;

/**
 * Image Slider
 */

import Flickity from 'flickity-fade';

const heroSlider = {
  cfg: {
    slider: '.js-heroSlider',
    sliderOptions: {
      cellAlign: 'left',
      contain: true,
      draggable: true,
      selectedAttraction: 0.01,
      friction: 0.15,
      lazyLoad: 2,
      groupCells: true,
      fade: true,
      setGallerySize: true,
      imagesLoaded: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: false,
      autoPlay: 5000,
      pauseAutoPlayOnHover: false,
      // watchCSS: true
    },
  },

  videosInitialized: false,

  // mouseMoveListener() {
  //     window.removeEventListener('mousemove', this.mouseMoveListener);
  //     this.lazyLoadVideos();
  // },
  //
  // initUserInteractionListener() {
  //     window.addEventListener('mousemove', this.mouseMoveListener);
  // },

  lazyLoadVideos() {
    const videos = document.querySelectorAll(`${this.cfg.slider} video`);
    videos.forEach((video) => {
      const { src } = video.dataset;
      video.src = src;

      video.addEventListener('play', () => {
        const videoContainer = video.parentElement;
        if (videoContainer) {
          const previewImage = videoContainer.querySelector(
            '.o-heroSlider__previewImage',
          );
          if (previewImage) {
            previewImage.classList.add('hidden');
          }
        }
      });
    });
  },

  bindNavigationButtonsEvents(flkty, buttons) {
    buttons.forEach((button, index) => {
      button.classList.remove('is-selected');
      button.addEventListener('click', (event) => {
        if (event.currentTarget.classList.contains('is-selected')) {
          return;
        }
        flkty.select(index);
        button.classList.add('is-selected');
      });
    });
  },

  setActiveNavigationButton(buttons, index) {
    buttons.forEach((button) => button.classList.remove('is-selected'));
    buttons[index].classList.add('is-selected');
  },

  initSlider($slider) {
    const $navigationButtons = [
      ...document.querySelectorAll('.js-sliderButtons .js-sliderButton'),
    ];
    const flkty = new Flickity($slider, this.cfg.sliderOptions);
    flkty.resize();

    setTimeout(() => {
      flkty.resize();
      $slider.classList.add('is-ready');
    }, 1000);

    flkty.on('select', (currentIndex) => {
      const $currentSlide = flkty.selectedElement;
      const $currentNavigationButton = $navigationButtons[currentIndex];
      $navigationButtons.forEach(($el) => {
        const $foreground = $el.querySelector(
          '.o-heroSlider__button-progress-foreground',
        );
        $foreground.removeAttribute('style');
      });

      const $currentProgressBar = $currentNavigationButton.querySelector(
        '.o-heroSlider__button-progress-foreground',
      );

      flkty.stopPlayer();
      const $video = $currentSlide.querySelector('video');
      if (!$video) {
        flkty.playPlayer();
        return;
      }

      $currentProgressBar.style.transform = 'translateX(-100%)';

      const videoIsReady = () => {
        $currentProgressBar.style.transitionDuration = `${$video.duration}s`;
        $currentProgressBar.style.transform = 'translateX(0%)';

        $video.autoplay = false;
        $video.currentTime = 0;
        $video.play();

        const onVideoDoneCallback = () => {
          flkty.next();
          flkty.playPlayer();
          $video.removeEventListener('ended', onVideoDoneCallback);
        };
        $video.addEventListener('ended', onVideoDoneCallback);

        flkty.on('select', () => {
          const $currentSlide2 = flkty.selectedElement;
          if (!$currentSlide2.querySelector('video')) {
            flkty.playPlayer();
          }
          $video.removeEventListener('ended', onVideoDoneCallback);
        });
      };

      // Video is ready to play
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
      if ($video.readyState >= 3) {
        videoIsReady();
      } else {
        $video.addEventListener('loadeddata', () => {
          if ($video.readyState >= 3) {
            videoIsReady();
          }
        });
      }
    });

    this.bindNavigationButtonsEvents(flkty, $navigationButtons);
    flkty.on('select', (index) => {
      this.setActiveNavigationButton($navigationButtons, index);
    });
  },

  init() {
    window.addEventListener('load', () => {
      // this.mouseMoveListener = this.mouseMoveListener.bind(this);
      // this.initUserInteractionListener();
      this.lazyLoadVideos = this.lazyLoadVideos.bind(this);

      setTimeout(this.lazyLoadVideos, 2500);
      const $sliders = [...document.querySelectorAll(this.cfg.slider)];
      if (!$sliders) {
        return;
      }

      $sliders.forEach(($slider) => this.initSlider($slider));
    });
  },
};

export default heroSlider;

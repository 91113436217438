import Flickity from 'flickity-fade';

const tabContentSlider = {
  cfg: {
    slider: '.js-tabContentSlider',
    wrapper: '.o-tabContentSlider__navigation',
    sliderOptions: {
      cellAlign: 'left',
      contain: true,
      draggable: true,
      selectedAttraction: 0.01,
      friction: 0.15,
      lazyLoad: 2,
      fade: true,
      groupCells: true,
      setGallerySize: true,
      imagesLoaded: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: false,
      // watchCSS: true,
      adaptiveHeight: false,
    },
  },

  customNav(flkty, elSearchWrapper = document) {
    const wrappers = [...elSearchWrapper.querySelectorAll(this.cfg.wrapper)];

    if (wrappers) {
      wrappers.forEach((wrapper) => {
        const navButtons = [...wrapper.querySelectorAll('.js-flickityLink')];

        if (navButtons) {
          navButtons.forEach((button, index) => {
            button.addEventListener('click', () => {
              flkty.select(index);
            });
          });

          flkty.on('select', () => {
            const previousSelectedButton = wrapper.querySelector('.is-active');
            if (previousSelectedButton) {
              previousSelectedButton.classList.remove('is-active');
            }
            const selectedButton = navButtons[flkty.selectedIndex];
            selectedButton.classList.add('is-active');
          });
        }
      });
    }
  },

  init() {
    const doResize = () => {
      const sliders = [...document.querySelectorAll(this.cfg.slider)];
      if (sliders) {
        sliders.forEach((slider) => {
          const flkty = new Flickity(slider, this.cfg.sliderOptions);
          flkty.resize();
          // only search for navi below parent element to prevent cross-slider nav
          this.customNav(flkty, slider.parentElement);

          setTimeout(() => {
            flkty.resize();
            slider.classList.add('is-ready');
          }, 1000);

          flkty.on('dragStart', () => (document.ontouchmove = (e) => e.preventDefault()));
          flkty.on('dragEnd', () => (document.ontouchmove = () => true));

          // observe for becoming visible → trigger resize()
          if ('IntersectionObserver' in window) {
            new IntersectionObserver(() => {
              flkty.resize();
            }).observe(slider);
          }
        });
      }
    };
    window.addEventListener('load', doResize());
    // window.addEventListener('resize', doResize());
  },
};

export default tabContentSlider;
